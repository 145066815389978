import { Stack, Button, Typography } from "@vapor/react-material";
import SearchInput from "../../core/SearchInput";
import { useState } from "react";
import { StyledChip } from "../../core/styledChip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import DatePickerComponent from "../../core/DatePicker";
import { CheckboxFilter } from "../CheckboxFilter";

const AccountantDashboardFilters: React.FC = () => {
  const [searchValue, setSearchValue] = useState("");
  const [openFilters, setOpenFilters] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const filters = [
    { category: "Circolari", checked: true },
    { category: "Agevolazioni fiscali", checked: false },
    { category: "Scadenze", checked: false },
    { category: "Fiscali", checked: true },
  ];

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignContent="center"
        paddingY="8px"
        marginX="20px"
        marginBottom="10px"
      >
        <Stack direction="row" alignItems="center">
          <SearchInput setSearchValue={setSearchValue} width={"300px"} />
          <StyledChip
            variant="filled"
            label="Bozze"
            size="medium"
            style={{ marginLeft: "30px", marginRight: "5px" }}
          />
          <StyledChip
            variant="filled"
            label="Programmate"
            size="medium"
            style={{ marginLeft: "10px", marginRight: "5px" }}
          />
          <StyledChip
            variant="filled"
            label="Inviate"
            size="medium"
            style={{ marginLeft: "10px", marginRight: "5px" }}
          />
        </Stack>
        <div
          style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          onClick={() => setOpenFilters((prev) => !prev)}
        >
          <FontAwesomeIcon
            icon={openFilters ? faChevronUp : faChevronDown}
            color="#0090d1"
            style={{ marginRight: "8px" }}
          />
          <Typography color={"#0090d1"}>Filtri</Typography>
        </div>
      </Stack>
      {openFilters && (
        <Stack
          direction="row"
          justifyContent="left"
          alignContent="center"
          paddingY="8px"
          marginX="20px"
          marginBottom="10px"
        >
          <Stack
            direction="row"
            alignItems="center"
            sx={{ width: "340px", gap: "20px" }}
          >
            <DatePickerComponent
              key={"from"}
              label={"Da data"}
              onChange={(e: any) => setFromDate(e.target.value)}
              value={fromDate}
              required={true}
            />
            <DatePickerComponent
              key={"to"}
              label={"A data"}
              onChange={(e: any) => setToDate(e.target.value)}
              value={toDate}
              required={true}
            />
          </Stack>
          <Stack direction="row" marginLeft="30px">
            <CheckboxFilter
              checkboxes={filters}
              handleCheckboxChange={(category: {
                category: string;
                checked: boolean;
              }) => {
                console.log(category);
              }}
            />
          </Stack>
        </Stack>
      )}
    </>
  );
};
export default AccountantDashboardFilters;
