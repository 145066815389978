import { faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import { VaporPage } from "@vapor/react-custom";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
} from "@vapor/react-material";
import { useState } from "react";
import { useNavigate } from "react-router";
// import { DocumentNotes } from "@onefront/documents-smart-components";

const NewsDetailPage: React.FC = () => {
  const [expandComment, setExpandComment] = useState(false);
  const navigate = useNavigate();
  return (
    <VaporPage
      headerLeft={[
        <IconButton onClick={() => navigate("/news")} size="large">
          <FontAwesomeIcon icon={faArrowLeft} color={"#0090d1"} size={"2xs"} />
        </IconButton>,
      ]}
      title="News name"
      sx={{
        "&.MuiCard-root .MuiBox-root .MuiTypography-titleSmall": {
          color: "#005075",
          fontWeight: "normal",
        },
        "&.MuiCard-root .MuiBox-root .MuiTypography-pageDescription": {
          color: "#5a6872",
        },
      }}
    >
      <Box sx={{ flexGrow: 1, height: "200%" }}>
        <Grid style={{ height: "100%" }} container spacing={1}>
          <Grid item xs={expandComment ? 8 : 12}>
            <div style={{ width: "value" }}>
              <img
                style={{ height: "429px", width: "100%", objectFit: "cover" }}
                src="https://cdn.sanity.io/images/tlr8oxjg/production/ada93729daf922ad0318c8c0295e5cb477921808-1456x816.png?w=3840&q=80&fit=clip&auto=format"
              />
            </div>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              paddingLeft="60px"
              paddingRight="60px"
              width="full"
              height="60px"
            >
              <Typography
                sx={{
                  color: " rgb(157, 166, 173)",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "bold",
                  height: " 17px",
                  letterSpacing: "0px",
                }}
              >
                23.06.2023 / Fiscali
              </Typography>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="70px"
              >
                <Tooltip
                  arrow
                  title="Salva"
                  placement="top"
                  sx={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    style={{
                      color: "rgb(0, 144, 209)",
                    }}
                    icon={faBookmark}
                  />
                </Tooltip>
                <Tooltip
                  arrow
                  title="Commenti"
                  placement="top"
                  sx={{ cursor: "pointer" }}
                >
                  <div onClick={() => setExpandComment((prev) => !prev)}>
                    <FontAwesomeIcon
                      style={{
                        color: "rgb(0, 144, 209)",
                      }}
                      icon={faCommentAlt}
                    />
                  </div>
                </Tooltip>
              </Stack>
            </Stack>
            <Divider color="#c9d9e8" />
            <Stack
              marginLeft="10%"
              marginRight="10%"
              marginTop="15px"
              justifyContent="center"
            >
              <Typography
                sx={{
                  color: "rgb(0, 80, 117)",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  lineHeight: "24px",
                  letterSpacing: "0.11px",
                  marginBottom: "10px",
                }}
              >
                Approvata in via definitiva alla Camera, nel pomeriggio del 4
                agosto 2023, la legge delega per la riforma fiscale 2023. This
                is title
              </Typography>
              <Typography
                sx={{
                  color: "rgb(90, 104, 114)",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  lineHeight: "20px",
                  letterSpacing: "0.01px",
                }}
              >
                Tra operazioni di voto a tempi record, citazioni di Jannacci e
                Troisi, e dediche a Silvio Berlusconi, è arrivato il via libera
                ufficiale sul testo prima della pausa estiva del Parlamento,
                come previsto e voluto dal Governo a cui spetta, ora, il compito
                di dare forma a tutte le novità tracciate nel testo. Dall’IRPEF
                all’IVA passando per le procedure di riscossione e accertamento,
                la legge delega approvata dal Parlamento durante l’iter
                parlamentare si è arricchita di nuove integrazioni e
                precisazioni, come quelle che riguardano una linea morbida sulle
                sanzioni per i contribuenti più collaborativi.
              </Typography>
              <Typography
                sx={{
                  color: "rgb(0, 80, 117)",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  lineHeight: "24px",
                  letterSpacing: "0.11px",
                  marginBottom: "10px",
                  paddingTop: "5px",
                }}
              >
                Approvata in via definitiva alla Camera!
              </Typography>
              <Typography
                sx={{
                  color: "rgb(90, 104, 114)",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  lineHeight: "20px",
                  letterSpacing: "0.01px",
                }}
              >
                La terza parte prevede, infatti, interventi sui procedimenti
                dichiarativi, così come su quelli di accertamento e riscossione
                o del contenzioso. Verranno inoltre riscritte le regole relative
                alle sanzioni amministrative e penali. La quarta parte del testo
                consiste in una vera e propria razionalizzazione e
                codificazione, per arrivare a Testi Unici che accorpino la
                normativa esistente. Particolare importanza verrà attribuita
                allo Statuto del Contribuente, che dovrebbe diventare una legge
                generale, portando un peso maggiore ad esempio nell’ambito del
                legittimo affidamento dei contribuenti.
              </Typography>
              <Typography
                sx={{
                  color: "rgb(0, 80, 117)",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  lineHeight: "24px",
                  letterSpacing: "0.11px",
                  marginBottom: "10px",
                  paddingTop: "5px",
                }}
              >
                Approvata in via definitiva alla Camera!
              </Typography>
              <Typography
                sx={{
                  color: "rgb(90, 104, 114)",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  lineHeight: "20px",
                  letterSpacing: "0.01px",
                }}
              >
                La terza parte prevede, infatti, interventi sui procedimenti
                dichiarativi, così come su quelli di accertamento e riscossione
                o del contenzioso. Verranno inoltre riscritte le regole relative
                alle sanzioni amministrative e penali. La quarta parte del testo
                consiste in una vera e propria razionalizzazione e
                codificazione, per arrivare a Testi Unici che accorpino la
                normativa esistente. Particolare importanza verrà attribuita
                allo Statuto del Contribuente, che dovrebbe diventare una legge
                generale, portando un peso maggiore ad esempio nell’ambito del
                legittimo affidamento dei contribuenti.
              </Typography>
              <Typography
                sx={{
                  color: "rgb(90, 104, 114)",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  lineHeight: "20px",
                  letterSpacing: "0.01px",
                }}
              >
                La terza parte prevede, infatti, interventi sui procedimenti
                dichiarativi, così come su quelli di accertamento e riscossione
                o del contenzioso. Verranno inoltre riscritte le regole relative
                alle sanzioni amministrative e penali. La quarta parte del testo
                consiste in una vera e propria razionalizzazione e
                codificazione, per arrivare a Testi Unici che accorpino la
                normativa esistente. Particolare importanza verrà attribuita
                allo Statuto del Contribuente, che dovrebbe diventare una legge
                generale, portando un peso maggiore ad esempio nell’ambito del
                legittimo affidamento dei contribuenti.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={4} display={expandComment ? "" : "none"}>
            <div style={{ height: "full", width: "full", color: "red" }}></div>
            {/* <DocumentNotes
              tenantItemId={"121212"}
              setCommentCount={"12"}
              documentId={"12121212"}
              workspaceId={"12"}
              itemClassification={"1212"}
            /> */}
          </Grid>
        </Grid>
      </Box>
    </VaporPage>
  );
};
export default NewsDetailPage;
