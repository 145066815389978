import VaporToolbar from "@vapor/react-custom/VaporToolbar";
import { Button } from "@vapor/react-material";

const CreateNewsToolbar = () => {
  return (
    <VaporToolbar
      size="large"
      variant="regular"
      contentLeft={[
        <>
          <Button
            onClick={() => {}}
            variant="outlined"
            sx={{ float: "right" }}
            size="small"
          >
            Anulla
          </Button>
        </>,
      ]}
      contentRight={[
        <>
          <Button
            onClick={() => {}}
            variant="outlined"
            sx={{ marginRight: "20px" }}
            size="small"
          >
            Salva bozza
          </Button>
          <Button onClick={() => {}} variant="contained" size="small">
            Invia
          </Button>
        </>,
      ]}
    ></VaporToolbar>
  );
};
export default CreateNewsToolbar;
