import {
  Autocomplete,
  InputLabel,
  Stack,
  TextField,
} from "@vapor/react-material";
import React, { useState } from "react";
import DatePickerComponent from "../../core/DatePicker";
import ShareNewsTable from "./ShareNewsTable";

const CreateNewsForm: React.FC = () => {
  const [oggetto, setOggetto] = React.useState<string>();
  const [dataInvio, setDataInvio] = React.useState<any>();
  const locale = "it";
  const top100Films = [{ label: "1" }, { label: "2" }, { label: "3" }];

  return (
    <Stack direction="column" width="50%" marginTop="20px">
      <div style={{ marginLeft: "15px", marginRight: "15px" }}>
        <TextField
          placeholder="Enter Oggetto"
          onChange={(e) => setOggetto(e.target.value)}
          label="Oggetto*"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "10px",
            alignItems: "baseline",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <InputLabel>Categoria*</InputLabel>
            <Autocomplete
              sx={{ marginRight: "20px" }}
              disablePortal
              size="small"
              options={top100Films}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
          <DatePickerComponent
            label="Data Invio*"
            onChange={setDataInvio}
            value={dataInvio}
            required={true}
            key="data-invio"
          />
        </div>
        <InputLabel>Destinatari*</InputLabel>
        <ShareNewsTable />
      </div>
    </Stack>
  );
};
export default CreateNewsForm;
