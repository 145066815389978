import React, { useCallback, useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel,
  Typography,
  Box,
  TableBody,
  Button,
  Tooltip,
} from "@vapor/react-material";
import { faInfoCircle, faX } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SelectedRowChip = ({ selected, setSelected }) => {
  const unselectRow = (row) => {
    setSelected(selected.filter((name) => name !== row));
  };

  return (
    <div style={{ display: "flex", paddingTop: "15px", alignItems: "center" }}>
      {!!selected.length && (
        <Typography
          color="#0090d1"
          sx={{
            cursor: "pointer",
            fontSize: "16px",
            whiteSpace: "nowrap",
          }}
          onClick={() => setSelected([])}
        >
          Annulla selezione
        </Typography>
      )}
      {selected.map((row) => (
        <div
          style={{
            display: "flex",
            background: "rgb(222, 240, 247)",
            borderRadius: "4px",
            margin: "10px",
            alignItems: "center",
            whiteSpace: "nowrap",
            paddingLeft: "12px",
            paddingRight: "12px",
            paddingTop: "4px",
            paddingBottom: "4px",
          }}
        >
          <Typography color="rgb(0, 80, 117)" sx={{ marginRight: "5px" }}>
            {row}
          </Typography>
          <FontAwesomeIcon
            icon={faX}
            onClick={() => unselectRow(row)}
            color={"#0090d1"}
            size={"xs"}
            style={{ marginLeft: "5px", marginRight: "5px", cursor: "pointer" }}
          />
        </div>
      ))}
    </div>
  );
};
const ShareNewsTable = () => {
  const [selected, setSelected] = React.useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  function createData(oggeto, categoria, destinari, data) {
    return { name: oggeto, calories: categoria, fat: destinari };
  }

  const rows = [
    createData("main p", "Personalizzati", "12.09.23"),
    createData("eat spa", "Agevolazioni fiscali", "10.09.23"),
    createData(" delega", "Srl, SpA", "11.08.23"),
    createData(" atego", "Forfettari", "03.08.23"),
  ];

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  const headCells = [
    {
      id: "oggetto",
      numeric: false,
      disablePadding: true,
      label: "Ragione sociale",
    },
    {
      id: "categoria",
      numeric: true,
      disablePadding: false,
      label: "Forma giuridica",
    },
    {
      id: "destinatari",
      numeric: true,
      disablePadding: false,
      label: "Codice ateco",
    },
  ];

  const numSelected = selected.length;
  const rowCount = rows.length;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <TableContainer
      component={Paper}
      sx={{
        paddingLeft: "15px",
        paddingRight: "15px",
      }}
    >
      <Table
        sx={{ minWidth: 700, marginTop: "25px" }}
        aria-label="customized table"
      >
        <TableHead shadow>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={handleSelectAllClick}
                inputProps={{
                  "aria-label": "select all",
                }}
              />
            </TableCell>
            {headCells.map((headCell) => (
              <>
                <TableCell
                  key={headCell.id}
                  // align={headCell.numeric ? "right" : "left"}
                  // padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    <Typography variant="boldLabel" sx={{ color: "#005075" }}>
                      {headCell.label}
                    </Typography>
                    {orderBy === headCell.id ? (
                      <Box component="span">
                        {/* {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"} */}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              </>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(rows, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const isItemSelected = isSelected(row.name);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, row.name)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.name}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                  >
                    <Typography variant="body">{row.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body">{row.calories}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body">{row.fat}</Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          {emptyRows > 0 && (
            <TableRow>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      {!!selected.length && (
        <>
          <SelectedRowChip selected={selected} setSelected={setSelected} />
          <div style={{ float: "inline-end" }}>
            <Button
              onClick={() => {}}
              variant="outlined"
              sx={{
                marginBottom: "20px",
                marginTop: "20px",
                marginRight: "5px",
              }}
              size="small"
            >
              Crea gruppo di utenti
            </Button>
            <Tooltip arrow title="Salva un gruppo di utenti da selezione" placement="top">
              <FontAwesomeIcon icon={faInfoCircle} color="#0090d1" />
            </Tooltip>
          </div>
        </>
      )}
    </TableContainer>
  );
};
export default ShareNewsTable;
