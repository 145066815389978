import { faBoxArchive, faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";
import { Button } from "@vapor/react-material";

const AccountantBulkActionToolbar = () => {
  return (
    <VaporToolbar
      size="medium"
      variant="regular"
      contentRight={[
        <>
          <Button
            onClick={() => {}}
            size="small"
            sx={{ color: "red", marginRight: "40px" }}
          >
            <FontAwesomeIcon
              icon={faTrashCan}
              style={{ marginRight: "10px" }}
            />
            Elimina selezionati
          </Button>
        </>,
        <>
          <Button onClick={() => {}} size="small" sx={{ marginRight: "30px" }}>
            <FontAwesomeIcon
              icon={faBoxArchive}
              style={{ marginRight: "10px" }}
            />
            Archivia selezionati
          </Button>
        </>,
      ]}
    ></VaporToolbar>
  );
};
export default AccountantBulkActionToolbar;
