import React, { useState } from "react";
import SearchInput from "../core/SearchInput";
import { Stack } from "@vapor/react-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { StyledChip } from "../core/styledChip";

const NewsFilters: React.FC = () => {
  const [selectedChip, setSelectedChip] = useState("");
  const [searchValue, setSearchValue] = useState("");

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      height="60px"
      marginLeft="4.7%"
      marginRight="4.7%"
      marginBottom="10px"
    >
      <StyledChip
        variant="filled"
        icon={<FontAwesomeIcon icon={faBook} color="#5a6872" />}
        label="Fatture"
        size="medium"
      />
      <SearchInput setSearchValue={setSearchValue} width={"240px"} />
    </Stack>
  );
};
export default NewsFilters;
