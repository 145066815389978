import { GridWrapper, Card } from "../styledComponents/NewsPageStyledComponent";
import NewsCard from "../components/NewsCard";
import Grid from "@vapor/react-material/Grid";
import Stack from "@vapor/react-material/Stack";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Divider } from "@vapor/react-material";
import NewsFilters from "../components/NewsFilters";
import { useGetAllNews } from "../services/hooks/useGetAllNews";
import { useEffect } from "react";

const NewsPage = () => {
  const { data, loading, hasLoaded, fetch, error } = useGetAllNews(
    "6aa3642e-3530-4f10-9f09-de4f6763c02d"
  );

  useEffect(() => {
    fetch();
    console.log("after fetch");
  }, []);

  return (
    <>
      <Stack
        direction="column"
        spacing={3}
        paddingTop="20px"
        marginLeft="16px"
        marginRight="16px"
      >
        <Grid item xs={4}>
          <Stack direction="column" alignItems="center">
            <Typography
              sx={{
                fontFamily: "Cairo",
                fontWeight: "600",
                textDecoration: "none",
                textTransform: "none",
                color: "#0077ad",
                padding: "0px",
                lineHeight: "1.5",
                fontSize: "20px",
              }}
            >
              NEWS
            </Typography>
            <Typography
              sx={{
                margin: "0px",
                fontFamily: "Cairo",
                color: "rgb(98, 121, 132)",
                fontWeight: "400",
                fontSize: "12px",
                padding: "0px",
                lineHeight: "1",
              }}
            >
              This is the description of the header
            </Typography>
          </Stack>
        </Grid>
        <Divider />
      </Stack>
      <NewsFilters />
      <GridWrapper
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
        }}
      >
        <Card>
          <NewsCard />
        </Card>
        <Card>
          <NewsCard />
        </Card>
        <Card>
          <NewsCard />
        </Card>
        <Card>
          <NewsCard />
        </Card>
        <Card>
          <NewsCard />
        </Card>
        <Card>
          <NewsCard />
        </Card>
        <Card>
          <NewsCard />
        </Card>
        <Card>
          <NewsCard />
        </Card>
      </GridWrapper>
    </>
  );
};
export default NewsPage;
