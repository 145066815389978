import FormControl from "@vapor/react-material/FormControl";
import FormGroup from "@vapor/react-material/FormGroup";
import FormControlLabel from "@vapor/react-material/FormControlLabel";
import Checkbox from "@vapor/react-material/Checkbox";

interface CheckboxProps {
  checkboxes: { category: string; checked: boolean }[];
  handleCheckboxChange: Function;
}

export const CheckboxFilter: React.FC<CheckboxProps> = ({
  checkboxes,
  handleCheckboxChange,
}) => {
  return (
    <FormControl>
      <label style={{ fontSize: "14px" }}>Categoria</label>
      <FormGroup row>
        {checkboxes &&
          checkboxes?.map((category: any, index: any) => (
            <FormControlLabel
              sx={{ marginRight: "40px" }}
              key={index}
              control={
                <Checkbox
                  checked={category.checked}
                  onChange={() => handleCheckboxChange(category)}
                />
              }
              label={category.category}
              labelPlacement="end"
            />
          ))}
      </FormGroup>
    </FormControl>
  );
};
