import Chip from "@vapor/react-material/Chip";
import styled from "@emotion/styled";

export const StyledChip = styled(Chip)`
  && {
    ${(props) =>
    props.categorylabel === props.selectedchip
      ? {
        backgroundColor: "#b9e6f6",
        borderColor: "#0090d1",
        borderWidth: "1px",
        color: "#1F698A"
      }
      : {
        backgroundColor: "rgb(216, 216, 217)",
        borderWidth: "1px",
        color: "#d8d8d9"
      }
  }
  }
`;