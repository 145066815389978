import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel,
  Typography,
  Box,
  TableBody,
  Tooltip,
} from "@vapor/react-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMessageLines,
  faCopy,
  faBoxArchive,
  faTrashCan,
} from "@fortawesome/pro-regular-svg-icons";

const TableView = ({ setSelectedItems }) => {
  const [selected, setSelected] = React.useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  useEffect(() => {
    setSelectedItems(selected);
  }, [selected]);

  function createData(oggeto, categoria, destinari, data) {
    return { name: oggeto, calories: categoria, fat: destinari, carbs: data };
  }

  const rows = [
    createData("nuove date seminar", "Circolari", "Personalizzati", "12.09.23"),
    createData(
      "bando per marketing digitale",
      "Agevolazioni fiscali",
      "Liberi professionisti",
      "10.09.23"
    ),
    createData("lege delega", "Fiscali", "Srl, SpA", "11.08.23"),
    createData("codici atego", "Scadenze", "Forfettari", "03.08.23"),
  ];

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  const headCells = [
    {
      id: "oggetto",
      numeric: false,
      disablePadding: true,
      label: "Oggetto",
    },
    {
      id: "categoria",
      numeric: true,
      disablePadding: false,
      label: "Categoria",
    },
    {
      id: "destinatari",
      numeric: true,
      disablePadding: false,
      label: "Destinatari",
    },
    {
      id: "data",
      numeric: true,
      disablePadding: false,
      label: "Data",
    },
    {
      id: "actions",
      disablePadding: true,
      label: "",
    },
  ];

  const numSelected = selected.length;
  const rowCount = rows.length;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <TableContainer
      component={Paper}
      sx={{
        paddingLeft: "15px",
        paddingRight: "15px",
      }}
    >
      <Table
        sx={{ minWidth: 700, marginTop: "25px" }}
        aria-label="customized table"
      >
        <TableHead shadow>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={handleSelectAllClick}
                inputProps={{
                  "aria-label": "select all",
                }}
              />
            </TableCell>
            {headCells.map((headCell) => (
              <>
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    <Typography variant="boldLabel" sx={{ color: "#005075" }}>
                      {headCell.label}
                    </Typography>
                    {orderBy === headCell.id ? (
                      <Box component="span">
                        {/* {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"} */}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              </>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(rows, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const isItemSelected = isSelected(row.name);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, row.name)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.name}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                  >
                    <Typography variant="body">{row.name}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body">{row.calories}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body">{row.fat}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body">{row.carbs}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body">{row.protein}</Typography>
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none">
                    <Typography
                      sx={{
                        color: "#005075",
                        fontFamily: "Roboto",
                        fontSize: "12px",
                        fontWeight: "normal",
                      }}
                    >
                      Inviata
                    </Typography>{" "}
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none">
                    <Tooltip
                      arrow
                      title="Messages"
                      placement="top"
                      sx={{ cursor: "pointer", margin: "12px" }}
                    >
                      <FontAwesomeIcon
                        icon={faMessageLines}
                        color="#0090d1"
                        size={"lg"}
                      />
                    </Tooltip>
                    <Tooltip
                      arrow
                      title="Copy"
                      placement="top"
                      sx={{ cursor: "pointer", margin: "12px" }}
                    >
                      <FontAwesomeIcon
                        icon={faCopy}
                        color="#0090d1"
                        size={"lg"}
                      />
                    </Tooltip>
                    <Tooltip
                      arrow
                      title="Archive"
                      placement="top"
                      sx={{ cursor: "pointer", margin: "12px" }}
                    >
                      <FontAwesomeIcon
                        icon={faBoxArchive}
                        color="#0090d1"
                        size={"lg"}
                      />
                    </Tooltip>
                    <Tooltip
                      arrow
                      title="Delete"
                      placement="top"
                      sx={{ cursor: "pointer", margin: "12px" }}
                    >
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        color="#0090d1"
                        size={"lg"}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          {emptyRows > 0 && (
            <TableRow
              style={{
                height: (dense ? 33 : 53) * emptyRows,
              }}
            >
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default TableView;
