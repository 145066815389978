import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "@onefront/react-sdk";
import Button from "@vapor/react-material/Button";
import Stack from "@vapor/react-material/Stack";
import TextField from "@vapor/react-material/TextField";

interface SearchButtonProps {
  setSearchValue: (value: string) => void;
  width: string;
}

const SearchInput: React.FC<SearchButtonProps> = ({
  setSearchValue,
  width = "200px",
}) => {
  const { t } = useTranslation("DOCS");
  return (
    <Stack direction="row" alignItems="end">
      <TextField
        placeholder={t("document.search.default")}
        variant="outlined"
        sx={{
          width: width,
        }}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setSearchValue("enter");
          }
        }}
      />
      <Button
        onClick={(e) => {
          setSearchValue("enter");
        }}
        size="medium"
        sx={{
          "&.MuiButtonBase-root.MuiButton-root.MuiButton-sizeMedium": {
            borderRadius: 0,
            minWidth: 40,
            p: 1,
          },
        }}
        variant="contained"
      >
        <SearchIcon />
      </Button>
    </Stack>
  );
};

export default SearchInput;
