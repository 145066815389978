import { Drawer, Stack } from "@vapor/react-material";
import { useState } from "react";
import SearchInput from "../../core/SearchInput";
import { VaporPage } from "@vapor/react-custom";
import { useGetImages } from "../../services/hooks/useGetImages";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";
import { Button } from "@vapor/react-material";

type UnsplashDrawerProps = {
  openDrawer: boolean;
  setOpenDrawer: (value: boolean) => void;
  onSelectImage: (value: string) => void;
};

const UnsplashDrawer: React.FC<UnsplashDrawerProps> = ({
  openDrawer,
  setOpenDrawer,
  onSelectImage,
}) => {
  const [searchValue, setSearchValue] = useState("office buildings");
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const images = [
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDpGHuWCqby50EHlYUDn6sv7o4gajAARiVOg&usqp=CAU",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZX12IEf9kusFE1PDbLJFT95jqE74X8PLEEA&usqp=CAU",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-OE82wJ0HhG_Wq_aq2wsa2NsUd_Ide0qW8Q&usqp=CAU",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFIrEcL_QQG_WHgBqceLaKm-g40NRzoAKeYg&usqp=CAU",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeKOSOXgxen9A0HtmOfCzY_rM8teetBEVZbg&usqp=CAU",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1tXfrgadMJxPtfdAlfZyWy8-VB4xjSzkKaw&usqp=CAU",
  ];
  const handleDrawer = () => {
    console.log("draweri onclick");
  };

  // const {
  //   data: images,
  //   loading,
  //   hasLoaded,
  //   fetch,
  //   error,
  // } = useGetImages("iHj4cbFcPKAfKdj-JMw-b7wsX7tjvzXWX26F-WxzXoM", searchValue);

  //   console.log(images);

  const onClickImage = (image: string) => {
    setSelectedImage(selectedImage === image ? null : image);
  };

  return (
    <Drawer
      anchor="right"
      open={openDrawer}
      onClose={handleDrawer}
      PaperProps={{
        style: {
          minWidth: 420,
          marginTop: 48,
          overflowY: "scroll",
          height: "calc(100% - 48px)",
        },
      }}
    >
      <VaporPage
        title="Unsplash"
        sx={{
          "&.MuiCard-root .MuiBox-root .MuiTypography-titleSmall": {
            color: "#005075",
            fontWeight: "normal",
          },
          "&.MuiCard-root .MuiBox-root .MuiTypography-pageDescription": {
            color: "#5a6872",
          },
        }}
      >
        <Stack sx={{ padding: "24px" }}>
          <SearchInput setSearchValue={setSearchValue} width={"420"} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            {images.map((image: string) => (
              <img
                onClick={() => {
                  onClickImage(image);
                  console.log(selectedImage);
                }}
                key={image}
                src={image}
                alt={"building"}
                style={{
                  marginTop: "10px",
                  cursor: "pointer",
                  border: `${
                    selectedImage === image ? "2px solid #0090d1" : ""
                  }`,
                }}
              />
            ))}
          </div>
        </Stack>
        <VaporToolbar
          size="medium"
          variant="regular"
          contentRight={[
            <>
              <Button
                onClick={() => {
                  setOpenDrawer(false);
                }}
                variant="outlined"
                sx={{ marginRight: "10px" }}
                size="small"
              >
                Chiudi
              </Button>
              <Button
                onClick={() => {
                  setOpenDrawer(false);
                  onSelectImage(selectedImage!);
                }}
                variant="contained"
                size="small"
                disabled={!selectedImage}
              >
                Importa foto
              </Button>
            </>,
          ]}
        />
      </VaporPage>
    </Drawer>
  );
};
export default UnsplashDrawer;
