import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { Box, IconButton, Stack } from "@vapor/react-material";
import { VaporPage } from "@vapor/react-custom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";
import UploadNewsComponent from "../components/createNews/UploadNewsComponent";
import CreateNewsForm from "../components/createNews/CreateNewsForm";
import CreateNewsToolbar from "../components/createNews/CreateNewsToolbar";
import UnsplashDrawer from "../components/createNews/UnsplashDrawer";
import { useState } from "react";

const CreateNewsPage: React.FC = () => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [image, setImage] = useState<string>();
  const navigate = useNavigate();
  return (
    <>
      <VaporPage
        headerLeft={[
          <IconButton onClick={() => navigate("/news")} size="large">
            <FontAwesomeIcon
              icon={faArrowLeft}
              color={"#0090d1"}
              size={"2xs"}
            />
          </IconButton>,
        ]}
        title="NUOVA NEWS"
        sx={{
          "&.MuiCard-root .MuiBox-root .MuiTypography-titleSmall": {
            color: "#005075",
            fontWeight: "normal",
          },
          "&.MuiCard-root .MuiBox-root .MuiTypography-pageDescription": {
            color: "#5a6872",
          },
        }}
        subtitle="Create a new item here"
      >
        <Stack direction="row">
          <UploadNewsComponent
            unsplashImage={image!}
            onOpenDrawer={(value: boolean) => setOpenDrawer(value)}
          />
          <CreateNewsForm />
        </Stack>
      </VaporPage>
      <CreateNewsToolbar />
      {openDrawer && (
        <UnsplashDrawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          onSelectImage={(value: string) => setImage(value)}
        />
      )}
    </>
  );
};
export default CreateNewsPage;
