import { useAuth, useGet } from "@onefront/react-sdk";
import { useEffect } from "react";
import { buildHeaders } from "./buildHeaders";

export function useGetAllNews(tenantId: string) {
  const authData = useAuth();

  const { data, loading, hasLoaded, fetch, error } = useGet(
    `https://documents-proxy-dev.agyo.io/proxy/newsApi/api/v1/news/search?tenantId=${tenantId}`,
    {
      lazy: true,
      headers: buildHeaders({ authData }),
    }
  );
  useEffect(() => {
    if (tenantId) fetch();
  }, [tenantId]);

  return { data, loading, hasLoaded, fetch, error };
}
