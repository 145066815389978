import { Divider, Grid, Stack, Typography } from "@vapor/react-material";
import NewsTableActions from "../components/accountantDashbord/NewsTableActions";
import TableView from "./TableView";
import AccountantDashboardFilters from "../components/accountantDashbord/AccountantDashboardFilters";
import AccountantBulkActionToolbar from "../components/accountantDashbord/AccountantBulkActionToolbar";
import { useState } from "react";

const AccountantDashboard: React.FC = () => {
  const [selectedItems, setSelectedItems] = useState<[]>([]);
  return (
    <>
      <Stack direction="column" spacing={3}>
        <Grid item xs={4}>
          <Stack direction="column" alignItems="center">
            <Typography
              sx={{
                fontFamily: "Cairo",
                fontWeight: "600",
                textDecoration: "none",
                textTransform: "none",
                color: "#0077ad",
                padding: "0px",
                lineHeight: "1.5",
                fontSize: "20px",
              }}
            >
              NEWS
            </Typography>
            <Typography
              sx={{
                fontFamily: "Cairo",
                color: "rgb(98, 121, 132)",
                fontWeight: "400",
                fontSize: "12px",
                padding: "0px",
                lineHeight: "1",
              }}
            >
              Invia notizie in tempo reale ai tuoi clienti
            </Typography>
          </Stack>
        </Grid>
        <Divider />
      </Stack>
      {/* <Stack direction="row" justifyContent="space-between"></Stack> */}
      <NewsTableActions />
      <AccountantDashboardFilters />
      <TableView
        setSelectedItems={(selectedItems: any) =>
          setSelectedItems(selectedItems)
        }
      />
      {selectedItems?.length > 0 && <AccountantBulkActionToolbar />}
    </>
  );
};
export default AccountantDashboard;
