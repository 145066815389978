import OneBox from "@drift/oneplatfront";
import { reactQueryService } from "./services/ReactQueryService";
import { news } from "./news-component";
const env = process.env.REACT_APP_REDIRECT

OneBox.run({
  settings: {
    one: {
      box: {
        app: {
          id: "onebox-boilerplate-cra-typescript-app-id", // <-- Id of the Launchpad's Products that we want to preload when the user haven't already picked one
        },
        module: {
          name: "onebox-boilerplate-cra-typescript", // <-- Name of the module to be developed
          version: "1.0.0", // <-- Version of the module to be developed
        },
      },
      layout: {
        title: "Onebox Boilerplate",
      },
      i18n: {
        options: {
          // Load all known namespaces
          // (more namespaces will be loaded on demand)
          ns: [
            "react-sdk",
            "onebox-boilerplate-cra-typescript",
            "namespace-two",
          ],
          defaultNS: "onebox-boilerplate-cra-typescript",
        },
      },
      auth: {
        token: { verify: true, refresh: true },
      },
      login: {
        target: {
          params: ["mode=redirect", `appId=${env ? "NEWS" : "demo"}`],
        },
      },
    },
  },
  features: [news],
  services: [reactQueryService],
}).catch(console.error);
