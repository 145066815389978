import styled from "styled-components";

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three equal columns */
`;
export const Card = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;

  padding: 10px;

  /* Remove left border for all cards except the first one in each row */
  &:nth-child(n) {
    border-left: none;
  }

  &:last-child:nth-child(3n) {
    border-bottom: none;
  }

  /* Remove right border for every third card in each row */
  &:nth-child(3n) {
    border-right: none;
  }
`;
