import { Uploader } from "@vapor/react-custom";
import { Stack, Typography } from "@vapor/react-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { faImage } from "@fortawesome/pro-light-svg-icons";
import unsplash from "../../assets/unsplash.jpg";
import Button from "@vapor/react-material/Button";
import { useState } from "react";
import UnsplashDrawer from "./UnsplashDrawer";

type UploadNewsComponentProps = {
  onOpenDrawer: (value: boolean) => void;
  unsplashImage: string;
};

const UploadNewsComponent: React.FC<UploadNewsComponentProps> = ({
  onOpenDrawer,
  unsplashImage,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <Stack
      direction="column"
      width="50%"
      height="screen"
      sx={{
        backgroundColor: "#f2f5f8",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          alignItems: "center",
          background: "rgb(255, 255, 255)",
          marginTop: "20px",
          height: "649px",
          paddingTop: "10px",
          marginBottom: "20px",
          width: "485px",
        }}
      >
        {!unsplashImage ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              height: "180px",
              width: "460px",
              background: "rgb(255, 255, 255)",
              border: "2px dashed #c9d9e8",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                justifyContent: "center",
                placeItems: "inherit",
                textAlign: "center",
              }}
            >
              <FontAwesomeIcon
                style={{
                  width: "60px",
                  height: "61px",
                }}
                color="rgb(201, 217, 232)"
                icon={faImage}
              />
              <Uploader onFileUpload={() => {}} buttonLabel="Carica immagine" />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                justifyContent: "center",
                placeItems: "inherit",
                textAlign: "center",
              }}
            >
              <img style={{ height: " 47px", width: "60px" }} src={unsplash} />
              <Button
                onClick={() => {
                  onOpenDrawer(true);
                  setOpenDrawer(true);
                }}
              >
                Scegli da Unsplash
              </Button>
            </div>
          </div>
        ) : (
          <img src={unsplashImage} />
        )}
        <div
          style={{
            height: "180px",
            width: "460px",
            marginTop: "7px",
            background: "rgb(255, 255, 255)",
            borderRadius: "0px",
            border: "2px dashed #c9d9e8",
          }}
        >
          <Typography
            color="#005075"
            sx={{ paddingTop: "8px", paddingLeft: "8px" }}
          >
            Titolo
          </Typography>
          <Typography sx={{ paddingTop: "4px", paddingLeft: "8px" }}>
            Inscerisi il tuo testo
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "219px",
            background: "#f2f5f8",
            width: "458px",
            marginTop: "7px",
            borderRadius: "0px",
            border: "2px dashed #c9d9e8",
          }}
        >
          <FontAwesomeIcon
            style={{
              width: "68px",
              height: "69px",
            }}
            color="#c9d9e8"
            icon={faPlus}
          />
          <Typography color="#0090d1">Aggiungi una notizia</Typography>
        </div>
      </div>
    </Stack>
  );
};
export default UploadNewsComponent;
