import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import { Button, Stack } from "@vapor/react-material";
import { useNavigate } from "react-router";

const NewsCard: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Stack
      direction="column"
      height="315px"
      width="347px"
      sx={{ cursor: "pointer" }}
      onClick={() => navigate("/news/2222")}
    >
      <div style={{ position: "sticky" }}>
        <img
          style={{ height: " 116px", width: "346px", objectFit: "cover" }}
          src="https://cdn.sanity.io/images/tlr8oxjg/production/ada93729daf922ad0318c8c0295e5cb477921808-1456x816.png?w=3840&q=80&fit=clip&auto=format"
        />
        <FontAwesomeIcon
          style={{
            position: "relative",
            color: "rgb(0, 144, 209)",
            bottom: "76%",
            left: "91%",
          }}
          icon={faBookmark}
        />
      </div>
      <Stack direction={"column"} marginTop={"-20px"}>
        <Typography
          style={{
            color: " rgb(157, 166, 173)",
            fontFamily: "Roboto-Bold",
            fontSize: "14px",
            fontWeight: "bold",
            height: " 17px",
            letterSpacing: " 0px",
            marginTop: "2px",
            marginBottom: "5px",
          }}
        >
          23.06.2023 / Fiscali
        </Typography>
        <Typography
          sx={{
            color: "rgb(21, 41, 53)",
            fontFamily: "Roboto",
            fontSize: "16px",
            fontWeight: "mid-bold",
            height: "24px",
            letterSpacing: "0.01px",
            textTransform: "uppercase",
            width: "272px",
            marginBottom: "5px",
          }}
        >
          Functions
        </Typography>
        <Typography
          sx={{
            color: "rgb(90, 104, 114)",
            fontSize: "14px",
            fontFamily: "Roboto",
            fontWeight: "normal",
            letterSpacing: "0.01px",
            lineHeight: "17px",
            marginBottom: "5px",
          }}
        >
          Finally, and here's the coup de grâce, we can run any pure function in
          parallel since it does not need access to shared memory and it cannot,
          by definition, have a race condition due to some side effect.
        </Typography>
        <Stack direction="row" justifyContent="center" marginTop="6px">
          <Button
            variant="outlined"
            style={{
              backgroundColor: "white",
              marginRight: "15px",
              borderRadius: "2px",
              border: "1px solid rgb(0, 144, 209)",
              height: "32px",
              width: " 111px",
            }}
            // onClick={() => {
            //   console.log("clicking here");
            // }}
          >
            <Typography color={"blue"}>Leggi tutto</Typography>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default NewsCard;
