import CreateNewsPage from "./pages/CreateNewsPage";
import NewsDetailPage from "./pages/NewsDetailPage";
import NewsPage from "./pages/NewsPage";
import AccountantDashboard from "./pages/AccountantDashboard";

export const news = () => {
  return [
    {
      target: "$ONE_LAYOUT_ROUTE",
      handler: {
        exact: true,
        path: "/news",
        component: NewsPage,
      },
    },
    {
      target: "$ONE_LAYOUT_ROUTE",
      handler: {
        exact: true,
        path: "/news/2222",
        component: NewsDetailPage,
      },
    },
    {
      target: "$ONE_LAYOUT_ROUTE",
      handler: {
        exact: true,
        path: "/all-news",
        component: AccountantDashboard,
      },
    }, {
      target: "$ONE_LAYOUT_ROUTE",
      handler: {
        exact: true,
        path: "/create-news",
        component: CreateNewsPage,
      },
    },
  ];
};
