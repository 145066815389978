import { useTranslation } from "@onefront/react-sdk";
import {
  AdapterDateFns,
  DatePicker,
  LocalizationProvider,
} from "@vapor/react-x-date-pickers";
import React, { FC } from "react";

type DatePickerProps = {
  label: string;
  onChange: Function;
  value: any;
  key: string;
  required: boolean;
};

const DatePickerComponent: FC<DatePickerProps> = ({
  label,
  onChange,
  value,
  key,
  required,
}: DatePickerProps) => {
  const { t } = useTranslation("DOCS");

  const handleChange = (newDate: any) => {
    const formattedDate = newDate ? newDate : null;
    onChange({ target: { value: formattedDate } });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        key={key}
        label={label}
        value={value ? value : null}
        onChange={handleChange}
      />
    </LocalizationProvider>
  );
};
export default DatePickerComponent;
