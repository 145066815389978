import { Button, Stack, Divider, Typography } from "@vapor/react-material";
import { Link, useNavigate } from "react-router-dom";

const NewsTableActions = () => {
  const navigate = useNavigate();
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignContent="center"
        paddingY="8px"
        // marginX="15px"
        borderBottom="1px solid #9da6ad"
        marginBottom="10px"
      >
        <Stack direction="row" justifyContent="space-around" marginLeft="20px">
          <Button
            sx={{
              background: "rgb(185, 230, 246)",
              borderWidth: "1px",
              borderRadius: "2px",
              border: "1px solid rgb(0, 144, 209)",
              color: "#7d97a0",
            }}
          >
            Inviate
          </Button>
          <Button>
            <Typography color="rgb(0, 80, 117)" fontFamily="Cairo">
              {" "}
              Archiviate
            </Typography>
          </Button>
          <Button
            sx={{
              background: "rgb(185, 230, 246)",
              borderWidth: "1px",
              borderRadius: "2px",
              border: "1px solid rgb(0, 144, 209)",
              color: "#7d97a0",
            }}
          >
            Cestino
          </Button>
        </Stack>
        <Button
          variant="contained"
          onClick={() => navigate("/create-news")}
          size="medium"
          sx={{
            background: "linear-gradient(90deg,#0090d1,#00c3ea)",
            marginRight: "20px",
            borderRadius: "2px",
          }}
        >
          Crea News
        </Button>
      </Stack>
    </>
  );
};
export default NewsTableActions;
